import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { Modal } from '../../components';
import { DateOfBirthForm } from 'components/OrderPanel/DateOfBirthForm';
import { fetchCurrentUser } from 'ducks/user.duck';
import { useUpdateStripeAccount } from 'hooks/api/users';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { post } from 'util/httpsClient';
import * as log from 'util/log';

export const DOBModal = () => {
  const { currentUser, isLoading: isLoadingUser } = useCurrentUser();
  const { mutateAsync: updateStripeAccount } = useUpdateStripeAccount();
  const { userGeolocation } = useGeolocation();
  const hasStripeAccount = currentUser?.attributes?.profile?.privateData?.stripeId;
  const dateOfBirth = currentUser?.attributes.profile.privateData?.dateOfBirth;
  const isOpen =
    !isLoadingUser && currentUser.id?.uuid && dateOfBirth && dateOfBirth === '1950-01-01';

  const dispatch = useDispatch();
  const { mutateAsync: createStripeAccount } = useMutation({
    mutationFn: async () => {
      const response = await post({ path: '/users/stripe', body: { userGeolocation } });
      return response;
    },
    onSettled: () => {
      dispatch(fetchCurrentUser());
    },
  });

  const handleDobModalSubmit = async () => {
    try {
      if (!hasStripeAccount) {
        await createStripeAccount();
      } else {
        await updateStripeAccount({});
      }
    } catch (error) {
      toast.error('Failed to set up your stripe account. Please contact our support team.');
      log.error(error, (error as any).code, undefined);
    }
  };

  return (
    <Modal open={isOpen} title="Please enter your date of birth" showCloseButton={false}>
      In order to be able to process payments we need to know your date of birth.
      <br />
      <br />
      <DateOfBirthForm showTermsPrompt={true} onDateOfBirthUpdated={handleDobModalSubmit} />
    </Modal>
  );
};
